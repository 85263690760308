<template lang="pug">
  div.wrap-module-client-record-list
    div(v-if="cards && cards[0]")
      ItemRecordCard(
        v-if="cards"
        v-for="record in cards"
        :content="record"
        :isClientView="true"
        :clientId="clientId")
    div(v-else).no-record.f.fh
      span 記録はありません。
</template>

<style lang="scss" scoped>
.wrap-module-client-record-list {
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  padding: 48px 0;
  .no-record {
    height: calc(100vh - 48px);
  }
}
</style>

<script>
// import db from '@/components/utils/firebase'
import ItemRecordCard from '@/components/item/ItemRecordCard'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateRecord, mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
    ItemRecordCard
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      cards: null
    }
  },
  computed: {
    ...mapStateRecord(['records'])
  },
  async created () {
    await this.loadRecords(this.clientId)
    this.cards = this.records.filter(e => { return true })
  },
  methods: {
    ...mapActionsRecord([
      'loadRecords'
    ])
  }
}
</script>
