<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="onFailedAuthentication")
    ClientHeader(:content="headerContent")
    ModalPage(
      v-if="clientId"
      :showModal="showModalPage"
      :modalPageContentId="modalPageContentId"
      @closeModalPage="closeModalPage")
    div.wrap-client-record
      ModuleClientRecordList(v-if="clientId" :clientId="clientId")
    ClientFooter
</template>

<style lang="scss" scoped>
.wrap-client-record {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import ClientHeader from '@/components/shared/ClientHeader'
import ClientFooter from '@/components/shared/ClientFooter'
import ModalPage from '@/components/shared/ModalPage.vue'
import ModuleClientRecordList from '@/components/module/ModuleClientRecordList'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    ClientHeader,
    ClientFooter,
    ModuleClientRecordList,
    ModalPage
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      headerContent: {
        label: '記録'
      },
      showModalPage: false,
      clientId: null,
      modalPageContentId: 'setRecord'
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        if (this.$route.params.recordId) {
          var recordType = this.$route.params.recordType

          switch (recordType) {
            case 'karte':
              this.modalPageContentId = 'setRecord'
              break
            case 'consultation':
              this.modalPageContentId = 'setConsultation'
              break
            case 'inbody':
              this.modalPageContentId = 'setInbody'
              break
          }

          this.headerContent = {
            label: '記録',
            leftAction: {
              method: () => {
                this.$router.push('/client_record')
              },
              icon: 'arrow_back_ios'
            }
          }

          this.showModalPage = true
        } else {
          this.headerContent = {
            label: '記録'
          }
          this.showModalPage = false
        }
      }
    }
  },
  created  () {
    if (this.$route.params.recordId) {
      this.showModalPage = true

      var recordType = this.$route.params.recordType
      switch (recordType) {
        case 'karte':
          this.modalPageContentId = 'setRecord'
          break
        case 'consultation':
          this.modalPageContentId = 'setConsultation'
          break
        case 'inbody':
          this.modalPageContentId = 'setInbody'
          break
      }

      this.headerContent = {
        label: '記録',
        leftAction: {
          method: () => {
            this.$router.push('/client_record')
          },
          icon: 'arrow_back_ios'
        }
      }
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/client-sign-in')
    },
    async onLoggedIn () {
      this.clientId = await db.collection('CLIENTS')
        .where('authedClientUid', '==', this.uid)
        .get()
        .then(q => {
          return q.docs[0].id
        })
    },
    closeModalPage () {
      this.showModalPage = false
    }
  }
}
</script>
